//
function process_UpdateDataAccount() {
    formx = document.forms['form_updateAccount'];
    let verif = false;

    if (formx.search_usx.options[formx.search_usx.selectedIndex].value == '') {
        formx.search_usx.focus();
        verif = true;
    }

    if (formx.request_list.options[formx.request_list.selectedIndex].value == '') {
        formx.request_list.focus();

        if (verif) {
            NotifyMssg = 'No ha seleccionado un usuario a editar. Favor verifique e intente nuevamente';
            NotifyerPopUp.create(NotifyMssg, titleAccounts, 'I');
            return false;
        }
    }

    if (formx.user_email.value == '') {
        NotifyMssg = 'Email en blanco o no fue ingresado. Favor verifique e intente nuevamente';
        NotifyerPopUp.create(NotifyMssg, titleAccounts, 'I');
        formx.user_email.focus();
        return false;
    }

    AnimationWallProgress(true);
    // '?accn=account/sessions/actions/modpriv';
    // 'mod_sessions\m.privilgs.php';
    formx.action = '/System/Query/Accounts/UpdateUserPermiss';
    formx.submit();
}
