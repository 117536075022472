// class: .BtnsLineExecution
// dataset.button

if (document.querySelector('.BtnsLineExecution')) {
    let fieldbtns = document.querySelector('.BtnsLineExecution');

    fieldbtns.addEventListener('click', function (e) {
        let action = e.target.dataset.button;

        switch (action) {
            case 'GoBackToLogin':
                goLink('/');
                break; 

            case 'VerifyLoginData': 
                verify_LoginData();
                break;

            case 'VerifyLoginBenefits': 
                verify_login_benefits(); 
                break;
                
            case 'LogOut':
                goLink('/System/Service/Access/LogOut');
                break; 

            case 'verify_DataForAccessRequest':
                verify_DataForAccessRequest();
                break; 

            case 'clean_FormGetAccess':
                clean_FormGetAccess();
                break; 

            case 'requestActivation': 
                get_requestActivation();
                break;

            case 'clean_form_raao': 
                clean_requestActivation();
                break;

            case 'requestNewAccount': 
                verify_DataRequestNewAccount();
                break;

            case 'clean_form_rna': 
                clean_requestNewAccount();
                break;

            case 'requestmfacode': 
                request_mfa_resetpass(); 
                break;

            case 'create_new_password': 
                get_mfa_newPassword(); 
                break;

            case 'clean_form_mfa': 
                clean_code_mfa(); 
                break;

            case 'user_profile': 
                break;

            case 'goto_backPage': 
                window.history.back(); 
                break;

            case 'restartToLogin': 
                goLink('/'); 
                break;

            case 'recordbackpage': 
                goLink('/System/Service/Access/BackPageRecord'); 
                break;
        }
    });
}
