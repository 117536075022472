//
let stepScroll =1;
if (document.getElementById('SectionHorizontalScroll')) {

    let btnRight = document.getElementById('btnMoveScrollRight');
    let btnLeft = document.getElementById('btnMoveScrollLeft');
        
    btnRight.addEventListener('click', function (evnt) {
        console.log('stepScroll btnRight: '+stepScroll);
        
        if (stepScroll==1) {
            verify_DataRequestNewAccount_step01();
        }
        if (stepScroll==2) {
            verify_DataRequestNewAccount_step02();
        }
        if (stepScroll==3) {
            verify_DataRequestNewAccount_step03();
        }
        console.log('stepScroll btnRight: '+stepScroll);
    })

    btnLeft.addEventListener('click', function (evnt) {
        console.log('stepScroll btnLeft: '+stepScroll);
        if (stepScroll==3) {
            formx = document.forms['form_access_new_account'];
            formx.privacypolicy.checked = false;
        }
        --stepScroll;
        StepScroll(stepScroll);
        console.log('stepScroll btnLeft: '+stepScroll);
    })

    btnRight.style.display = 'block';
}


function StepScroll(position) {
    let bhs = document.getElementById('SectionHorizontalScroll');
    // let btnGetNew = document.getElementById('btnGetNewAccount');
    let btnClean = document.getElementById('btnCleanFormrna');
    let btnRight = document.getElementById('btnMoveScrollRight');
    let btnLeft = document.getElementById('btnMoveScrollLeft');
    stepScroll =position;

    switch (stepScroll) {
        case 1:                 
            bhs.classList.remove('step02');
            bhs.classList.remove('step03');
            bhs.classList.add('step01');
            btnClean.style.display ='block';
            btnLeft.style.display = 'none';
            break;

        case 2: 
            bhs.classList.remove('step01');
            bhs.classList.remove('step03');
            bhs.classList.add('step02');
            btnClean.style.display ='none';
            // btnGetNew.style.display ='none';
            btnRight.style.display ='block';
            btnRight.innerHTML ='Siguiente';
            btnLeft.style.display = 'block';
            break;

        case 3: 
            bhs.classList.remove('step01');
            bhs.classList.remove('step02');
            bhs.classList.add('step03');
            // btnGetNew.style.display ='block';
            btnClean.style.display ='none';
            // btnRight.style.display ='none';
            btnRight.innerHTML ='Solicitar Perfil Acceso';
            break;

        case 4: 
            break;
    }
}
