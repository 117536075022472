//** PROCESS ACTIVATE ACCOUNT NEW USER */
function verify_DataRequestNewAccount_step01() {
    let formx = document.forms['form_access_new_account'];
    
    // REGISTER
    // ubussiness
    // passport: upp
    // run: urun
    // dv: udv 

    try {
        // credential number nocredential
        Validation_Input(
            formx.firstname,
            'El campo se encuentra en blanco o no fue ingresado',
            'Primer Nombre',
            ['names']
            );
    
        Validation_Input(
            formx.secondname,
            'El campo se encuentra en blanco o no fue ingresado',
            'Segundo Nombre',
            ['names']
            );
    
        Validation_Input(
            formx.surname,
            'El campo se encuentra en blanco o no fue ingresado',
            'Apellido Paterno',
            ['names']
            );
            
        Validation_Input(
            formx.lastname,
            'El campo se encuentra en blanco o no fue ingresado',
            'Apellido Materno',
            ['names']
            );
    
    } catch (error) {
        InputController(error);
        return false;
    }
    
    StepScroll(2);
}

function verify_DataRequestNewAccount_step02() {
    let formx = document.forms['form_access_new_account'];

    try {
        Validation_Input(
            formx.rolwork,
            'El campo se encuentra en blanco o no fue ingresado',
            'Cargo o Rol'
            );
        
        Validation_Input(
            formx.miningGroup,
            'No se ha seleccionado Grupo Minero asociado al colaborador',
            'Grupo Minero'
            );

        Validation_Input(
            formx.uphone,
            'El campo Número de Celular se encuentra en blanco o no fue ingresado',
            'Número de Celular'
            );

        let idemail = formx.idemail;
        Validation_Input(
            idemail,
            'El campo Email se encuentra en blanco o no fue ingresado',
            'Email',
            ['email']
            );

        // orisip
        // uwgroup

    } catch (error) {
        InputController(error);
        return false;
    }
    
    StepScroll(3);
}

function verify_DataRequestNewAccount_step03() {
    let formx = document.forms['form_access_new_account'];
    
    try {
        // uclass
        // idpsswd
        
        let newpsswd = formx.newpsswd;
        Validation_Input(
            newpsswd,
            'La contraseña se encuentra en blanco o no fue ingresado',
            'Nueva Contraseña'
            );
        
        let confirmpsswd = formx.confirmpsswd;
        Validation_Input(
            confirmpsswd,
            'La contraseña de verificación se encuentra en blanco o no fue ingresado',
            'Contraseña Confirmación',
            ['confirm'],
            newpsswd
            );

        Validation_Input(
            formx.privacypolicy,
            'Antes de continuar debe Aceptar las condiciones de la Politica de Privacidad',
            'Política Privacidad'
            );
    
    } catch (error) {
        InputController(error);
        return false;
    }

    AnimationWallProgress(true);

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return 'false';
        }
        return 'true';
    }

    let xemail = formx.idemail.value.trim();
    if (xemail != '') {

        let regstart = new Date().getTime();
        const xpath = '/System/Service/Email/ControlCheckEmail';

        let frmData = new FormData;
        frmData.append('xemail', xemail);

        const request = new Request(xpath, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: frmData,
            //headers: new Headers()
        });

        fetch(request)
            .then(res => {
                //console.info('status: ' + res.status);
                //return res.json();
                if (res.status == 200) {
                    return res.text();
                } else {
                    throw "Respuesta incorrecta del servidor"
                }
            })
            .then(data => {
                //console.log('data: ' + data);
                //console.log('length: ' + data.length);
                //console.log('isJson: ' + isJson(data));

                let jsonResponse = JSON.parse(data);

                if (jsonResponse['check'] == 'VALID') {
                    NotifyMssg = 'Email unico, habilitado para usar en Login.';
                    NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');

                    AnimationWallProgress(true);
                    formx.action = '/System/Query/Accounts/RequestNewAccount';

                    let crypt = document.createElement('input');
                    formx.appendChild(crypt);
                    crypt.name = 'psha512';
                    crypt.type = 'hidden';
                    crypt.value = hex_sha512(newpsswd.value.trim());

                    confirmpsswd.value = '';
                    newpsswd.value = '';

                    formx.submit();

                } else {
                    AnimationWallProgress(false);

                    NotifyMssg = 'Este Email se encuentra en uso por otra cuenta, favor utilice otro o verifique usuario.';
                    NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
                }

                let regload = new Date().getTime();
                console.info('Time Load: ' + ((regload - regstart) / 60).toFixed(2) + ' s');
            })
            .catch(err => {
                ErrorController(err);
                // console.error('ERR: ' + err.name);
                // console.error('ERR: ' + err.message);
                // console.error('ERR: ' + err.toString());
                // console.error('ERR: ' + err.stack);
            });
    }
}

function clean_requestNewAccount() {
    let formx = document.forms['form_access_new_account'];

    formx.firstname.value = '';
    formx.secondname.value = '';
    formx.surname.value = '';
    formx.lastname.value = '';
    // formx.nocredential.value = '';
    formx.idemail.value = '';
    formx.uphone.value = '';
    formx.newpsswd.value = '';
    formx.confirmpsswd.value = '';
    formx.miningGroup.value = '';
    formx.privacypolicy.checked = false;
}
