/** CHECK PASSWORD STRONGER */

function CheckPasswordStrength(password) {

    let text_strength = document.getElementById('comm_strench');
    let passwordLenght = password.length;

    //TextBox left blank.
    if (passwordLenght == 0) {
        text_strength.innerHTML = '';
        return;
    }

    //Regular Expressions.
    let passed = 0;
    let regex = new Array();
    regex.push('[A-Z]'); //Uppercase Alphabet.
    regex.push('[a-z]'); //Lowercase Alphabet.
    regex.push('[0-9]'); //Digit.
    regex.push('[$@$!%*#?&]'); //Special Character.

    //Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            passed++;
        }
    }

    //Validate for length of Password.
    if (passed > 2 && passwordLenght > 8) {
        passed++;
    }

    //Display status.
    let color = '';
    let strength = '';
    let digitPassword = passwordLenght.toString();

    switch (passed) {
        case 0:
        case 1: strength = '[' + digitPassword + ' digitos] Débil'; //Weak
            color = '#e40000';
            break;
        case 2: strength = '[' + digitPassword + ' digitos] Normal'; //Good
            color = '#d66800';
            break;
        case 3: strength = '[' + digitPassword + ' digitos] Mejorada'; //Strong
            color = '#22aa00';
            break;
        case 4: strength = '[' + digitPassword + ' digitos] Resistente'; //Strong
            color = '#187a00';
            break;
        case 5: strength = '[' + digitPassword + ' digitos] Muy Resistente'; //Very Strong
            color = '#2255bb';
            break;
    }

    text_strength.innerHTML = strength;
    text_strength.style.color = color;
}
