// @ts-check
let platform_system = 'Plataforma IPM CORE';

NotifyTitle = platform_system;

let ttl02 = 'Control Registro de Usuario';
let ttl03 = 'Validación Usuario';
let ttl04 = 'Cambio Contraseña';

//let titleActivation = 'Activación de Cuenta';

// ACCOUNTS
const titleAccounts = 'Administración Perfiles';

// actions
if (document.getElementById('flash_alert')) {
    let flash_alert = document.getElementById('flash_alert');

    flash_alert.addEventListener('click', function (e) {
        //Notify.hidden(e.target);
    });
}
//
