//
if (document.querySelector('.field_environment')) {

    document.querySelectorAll('[data-envbtn]')
    .forEach(e => e.addEventListener('click', function (elm) {
        
        let btn = elm.target;    
        let action = btn.dataset.envbtn;
    
        AnimationWallProgress(true);
        console.log('action:', action);

        switch (action) {
            case 'switch_EnvironmentSiemensChile': 
                Switch_Environment('SICL'); 
                break;

            case 'switch_EnvironmentInnoCsSd': 
                Switch_Environment('CSSD'); 
                break;
    
            case 'switch_EnvironmentSiemensSpot': 
                Switch_Environment('SPOT'); 
                break;
    
            case 'switch_EnvironmentSiemensPeru': 
                Switch_Environment('SIPE'); 
                break;
    
            case 'switch_EnvironmentCMDIC': 
                Switch_Environment('CMDIC'); 
                break;
    
            case 'switch_EnvironmentCDGM': 
                Switch_Environment('DGM'); 
                break;
    
            case 'switch_EnvironmentCAND': 
                Switch_Environment('DAND'); 
                break;
    
            case 'switch_EnvironmentMLP': 
                Switch_Environment('MLP'); 
                break;
        }

    }));


}

if (document.getElementById('btnSavePreferences')) {
    let btnSavePreferences = document.getElementById('btnSavePreferences');

    btnSavePreferences.addEventListener('click', function (e) {
        // TODO:
        window.history.back();
    })
}
