//** PROCESS ACTIVATE ACCOUNT USER EXISTS */
function get_requestActivation() {

    formx = document.forms['form_activation_account'];
    
    try {
        Validation_Input(
            formx.uphone,
            'Su Número de Mobile se encuentra en blanco o no fue ingresado',
            'Celular Principal'
            );
    
        Validation_Input(
            formx.idemail,
            'El registro Email se encuentra en blanco o no fue ingresado',
            'Email de Acceso'
            );
    
        let enrpsswd = formx.enrpsswd;
        Validation_Input(
            enrpsswd,
            'El registro Password se encuentra en blanco o no fue ingresado',
            'Nueva Contraseña'
            );
        
        Validation_Input(
            formx.confirmpsswd,
            'La contraseña de verificación se encuentra en blanco o no fue ingresado',
            'Contraseña Confirmación',
            ['confirm'],
            enrpsswd
            );
    
        Validation_Input(
            formx.miningGroup,
            'El registro Grupo Minero se encuentra en blanco o no fue ingresado',
            'Grupo Minero'
            );
    
        Validation_Input(
            formx.ProcessMain,
            'Debe seleccionar un proceso asociado a su gestión, el Jefe de proceso aprobara su Activación',
            'Proceso de Gestión'
            );
    
    } catch (error) {
        InputController(error);
        return false;
    }

    create_InputPasswordHidden(
        formx,
        'psha512',
        enrpsswd
    );

    confirmpsswd.value = '';
    enrpsswd.value = '';
    
    AnimationWallProgress(true);
    formx.action = '/System/Query/Accounts/ProcessEnableAccount';
    formx.target = ''; // _blank
    formx.submit();
}

function clean_requestActivation() {
    formx = document.forms['form_activation_account'];

    formx.uphone.value = '';
    formx.idemail.value = '';
    formx.enrpsswd.value = '';
    formx.confirmpsswd.value = '';
    formx.miningGroup.value = '';
}

// if (document.getElementById('confirmpsswd')) {
//     let confirmation = document.getElementById('confirmpsswd');

//     confirmation.addEventListener('keyup', function (e) {
//         //console.info('keypress: ' + event.keyCode);

//         let password = document.getElementById('enrpsswd');

//         Passw_Confirm(password.value.trim(), confirmation.value.trim());
//     });
// }
