/** SET ACTIONS MAIN DOOR */
// ID: BtnsComplementActions
// dataset.action

import { BXToastNotification } from "carbon-web-components";

if (document.getElementById('BtnsComplementActions')) {
    let setactions = document.getElementById('BtnsComplementActions');

    setactions.addEventListener('click', function (e) {

        switch (e.target.dataset.action) {
            case 'LogOut':
                goLink('/System/Service/Access/LogOut');
                break;

            case 'ValidateCodeMFA':
                goLink('/System/Command/Access/GetNewPassword');
                break;

            case 'LoginSSOSiemens':
                NotifyMssg = 'Login SSO, no habilitado';
                NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
                break;

            case 'RecoveryPassword':
                goLink('/System/Command/Access/GetCodeMfa');
                break;

            case 'RequestGetAccess':
                goLink('/System/Command/Access/GetAccess');
                break;

            case 'LoginSSO':        
            default:
                goLink('/');
                break;
        }
        //console.log('go service: ' + e.target.dataset.action);
    });
}
