//** ACCESS LOGIN */
if (document.getElementById('last_input_login')) {

    document.getElementById('last_input_login')
    .addEventListener('keypress', function (e) {
        if (e.code === 'Enter') {
            verify_LoginData();
        }
    });
}

function verify_LoginData() {
    let formx = document.forms['form_account'];
    
    try {
        Validation_Input(
            formx.idemail,
            'El Email se encuentra en blanco o no fue ingresado',
            'Login Email'
            );
    
        Validation_Input(
            formx.idpsswd,
            'La Contraseña se encuentra en blanco o no fue ingresada',
            'Contraseña',
            ['light']
            );
    
    } catch (error) {
        InputController(error);
        return false;
    }
    
    AnimationWallProgress(true);
    formx.action = '/System/Query/Access/LoginVerification';
    formx.target = ''; // _blank

    create_InputTextHidden(
        formx, 'xmail', formx.idemail
    );
    
    create_InputPasswordHidden(
        formx, 'psha512', formx.idpsswd
    );

    formx.submit();
}
