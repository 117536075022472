/** FILTERS */
let va1 = /\d/; //Coincide con cualquier carácter no numérico. Equivalente a [^0-9].
let va2 = /\s/; //Coincide con un carácter de espacio, entre ellos incluidos espacio, tab, salto de página, salto de linea y retorno de carro.
let va3 = /\W/; //Coincide con todo menos caracteres de palabra. Equivalente a [^A-Za-z0-9_].
let val4 = /\S+@\S+\.\S+/;

if (document.forms['form_account']) {
    let form_Account = document.forms['form_account'];
}

function ValidSRUT() {
    let prun = form_Account.urun.value.trim();
    let statvrut = Fn.validaRut(prun);
    let comment_credential = document.getElementById('comm_run');

    if (statvrut) {
        comment_credential.innerHTML = '';
    } else {
        comment_credential.innerHTML = '* RUN ó DV No Válido';
    }
}

function ValidSPASSPORT() {
    let validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'; // SPA
    let regsaid = new RegExp('^([A-Z a-z]){1}([0-9]){7}$');

    let urun = form_Account.urun.value.trim();
    let pssprt = urun.toString().toUpperCase();

    let nie = pssprt
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    let letter = pssprt.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8)) % 23;
    let comment_credential = document.getElementById('comm_run');

    if (validChars.charAt(charIndex) === letter) { }

    if (regsaid.test(pssprt) == false) {
        comment_credential.innerHTML = '';
    } else {
        comment_credential.innerHTML = '* PASSPORT Invalid';
    }
}


if (document.getElementById('urun')) {
    let urun = document.getElementById('urun');

    urun.addEventListener('keyup', function (e) {
        let docidentify = document.getElementById('docidentify');
        let form_Account = document.forms['form_account'];

        if (form_Account.docidentify.value == 'RUN') {
            ValidSRUT();
        } else {
            ValidSPASSPORT();
        }
    });
}
