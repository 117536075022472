// login benefits
function verify_login_benefits() {
    let formx = document.forms['form_benefits'];

    try {
        Validation_Input(
            formx.nocredential,
            'El RUN ingresado se encuentra en blanco o no fue ingresado',
            'Acceso Beneficios'
            );
    
        Validation_Input(
            formx.surname,
            'El Apellido Paterno se encuentra en blanco o no fue ingresado',
            'Acceso Beneficios'
            );
    
    } catch (error) {
        InputController(error);
        return false;
    }
    
    AnimationWallProgress(true);
    formx.action = '/System/Query/Benefits/VerificationAccess';
    formx.target = ''; // _blank

    create_InputTextHidden(
        formx, 'x-run', formx.nocredential
    );
    
    create_InputTextHidden(
        formx, 'x-surname', formx.surname
    );

    formx.submit();
}
