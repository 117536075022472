// VERIFY MFA AND SET NEW PASSWORD
function get_mfa_newPassword() {
    let endText = '. Favor verifique e intente nuevamente';
    let form_new_password = document.forms['form_new_password'];

    let codemultifactor = form_new_password.codemultifactor;
    let codemfa = codemultifactor.value.trim();
    if (codemfa == '') {
        NotifyMssg = 'Su código de verificación MFA se encuentra en blanco' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
        codemultifactor.focus();
        return false;
    }
    if (codemfa.length != 16) {
        NotifyMssg = 'Su código de verificación MFA no cumple el estandard' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
        codemultifactor.focus();
        return false;
    }

    // docidentify

    let nocredential = form_new_password.nocredential;
    if (nocredential.value.trim() == '') {
        NotifyMssg = 'Su Número de Credencial se encuentra en blanco o no fue ingresado' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
        nocredential.focus();
        return false;
    }

    if (nocredential.value.trim().length < 6) { /*isNaN(urun.value) || */
        NotifyMssg = 'Error Numero Credencial : no cumple con la cantidad de digitos minimos' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
        nocredential.focus();
        return false;
    }

    let docidentify = form_new_password.docidentify;
    switch (docidentify.value) {
        case 'run': // RUN
            let statvrut = Fn.validaRut(nocredential.value);
            if (!statvrut) {
                NotifyMssg = 'RUN NO VALIDO, No es congruente con el Digito Verificador' + endText;
                NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
                nocredential.focus();
                return false;
            }
            break;

        case 'passport': // PASSPORT
            break;
            
        case 'dni': // PASSPORT
            break;
    }

    let newpassword = form_new_password.newpassword;
    if (newpassword.value.trim() == '') {
        NotifyMssg = 'La Contraseña se encuentra en blanco o no fue ingresada' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
        newpassword.focus();
        return false;
    }
    let limit = 12;
    if (newpassword.value.trim().length < limit) {
        NotifyMssg = 'Error PASSWORD : La Contraseña debe contener un largo de al menos ' + limit.toString() + ' caracteres' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
        newpassword.focus();
        return false;
    }
    let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    if (!re.test(newpassword.value.trim())) {
        NotifyMssg = 'Error PASSWORD : El Passwords al menos debe contener un numero, letras minúsculas y letras mayúsculas' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
        newpassword.focus();
        return false;
    }

    let confirmpsswd = form_new_password.confirmpsswd;
    if (confirmpsswd.value.trim() == '') {
        NotifyMssg = 'La Contraseña de verificación se encuentra en blanco o no fue ingresada' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
        confirmpsswd.focus();
        return false;
    }
    if (newpassword.value.trim() != confirmpsswd.value.trim()) {
        NotifyMssg = 'Error PASSWORD : Al comparar su contraseña y la confirmacion de contraseña estas no son exactas' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
        confirmpsswd.focus();
        return false;
    }


    let crypt = document.createElement('input');
    form_new_password.appendChild(crypt);
    crypt.name = 'psha512';
    crypt.type = 'hidden';
    crypt.value = hex_sha512(newpassword.value.trim());

    confirmpsswd.value = '';
    newpassword.value = '';

    AnimationWallProgress(true);
    form_new_password.action = '/System/Query/Accounts/SetNewPassword';
    form_new_password.submit();
}

function clean_code_mfa() {
    let form_new_password = document.forms['form_new_password'];
    form_new_password.codemultifactor.value = '';
    form_new_password.nocredential.value = '';
    form_new_password.newpassword.value = '';
    form_new_password.confirmpsswd.value = '';
}

if (document.getElementById('codemultifactor')) {

    document.getElementById('codemultifactor').addEventListener('keyup', function (e) {
        //
        let comm_codemfa = document.getElementById('comm_codemfa');
        let form_new_password = document.forms['form_new_password'];
        let message = form_new_password.codemultifactor.value.trim().length;

        comm_codemfa.innerHTML = message.toString() + ' Digitos';
    })

}
