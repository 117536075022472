//
function go_stage(position) {
    let urlx = '/System/Command/Access/LoginCore';

    switch (position) {
        case 'BLACK': urlx = '/System/Service/Access/BlackOut';
    }

    window.location.replace(urlx);
}
