//** BUTTONS ACTIONS */
// class: buttons_actionPack
// dataset.button

if (document.getElementById('buttons_actionPack')) {
    let buttons_actionPack = document.getElementById('buttons_actionPack');

    buttons_actionPack.addEventListener('click', function (event) {

        let action = event.target.dataset.button;
        let xpath = '';
        let optionHref = true;
        //console.info('action : ' + action);

        switch (action) {
            // ACCOUNTS
            case 'updateDataAccount': process_UpdateDataAccount(); break;

            case 'passwordDefault': process_GeneratePasswordDefault(); break;

            case 'goHome': xpath = '/Development/Command/Manager/Home'; break;
        }

        if (xpath != '') {
            if (optionHref) {
                window.location.href = xpath;
            } else {
                window.open(xpath, '_blank');
            }
        }
    })
}
