//
function transportInfoUser(
    select_usx:object,
    origine:string
    ) {
    let regstart = new Date().getTime();

    formx = document.forms['form_updateAccount'];
    formx.origine.value = origine;
    
    let track_usx = document.getElementById("track_usx");
    let guid_userx = select_usx.options[select_usx.selectedIndex].value;

    if (guid_userx == '') {
        track_usx.innerHTML = 'Usuario No Seleccionado';

        formx.user_run.value = '';
        formx.pa_rolework.value = '';
        formx.pa_roleplatform.value = '';
        formx.pa_OperationMine.value = '';
        formx.pa_Business.value = '';
        formx.user_email.value = '';
        formx.user_mobile.value = '';
        formx.pa_statusAccess.value = '';

        // formx.pa_tic.value = '';
        // formx.pa_profiles.value = '';
        // formx.pa_advancedLogistics.value = '';
        // formx.pa_contractManagement.value = '';
        // formx.pa_controlDocument.value = '';
        // formx.pa_development.value = '';
        // formx.pa_ehsq.value = '';
        // formx.pa_eqinspector.value = '';
        // formx.pa_hxm.value = '';
        // formx.pa_lockcard.value = '';
        // formx.pa_operations.value = '';
        // formx.pa_planning.value = '';
        // formx.pa_tooltime.value = '';
        // formx.pa_workpacks.value = '';

        return false;

    } else {
        track_usx.innerHTML = select_usx.options[select_usx.selectedIndex].text;
    }
    
    formx.guidtracking.value = guid_userx;

    let xpath = '/System/Service/Accounts/InformationUser';
    let frmData = new FormData;

    frmData.append('search_usx', guid_userx);
    frmData.append('origine', origine);

    const request = new Request(xpath, {
        method: 'POST',
        body: frmData,
        credentials: 'include',
        mode: 'same-origin',
    });

    fetch(request)
        .then(function (res) {
            //console.info('status: ' + res.status);
            return res.json();
        })
        .then(function (data) {
            //console.log('data: ' + data);
            //console.log('length: ' + data.length);

            if (data.length > 4) {
                let Response = JSON.parse(data);

                if (origine == 'PT') {
                    //console.log('parse: ' + Response[0]['ID_RUN']);

                    formx.user_run.value = Response[0]['ID_RUN'] + '-' + Response[0]['RUN_DV'];
                    formx.pa_rolework.value = Response[0]['FK_ROLE_WORK'];
                    formx.pa_roleplatform.value = Response[0]['SY_ROLE_PLATFORM'];
                    formx.pa_OperationMine.value = Response[0]['FK_OPERATION_MINE'];
                    formx.pa_Business.value = Response[0]['FK_ORGANIZATION'];
                    formx.user_email.value = Response[0]['AP_LOGIN_MAIL'];
                    formx.user_mobile.value = Response[0]['MOBILE'];

                    let selectOptions = formx.pa_statusAccess.options;
                    selectOptions[0].value = Response[0]['AP_STATUS'];
                    selectOptions[0].text = Response[0]['AP_STATUS'];
                    formx.pa_statusAccess.selectedIndex = "0";

                } else {
                    //console.log('parse: ' + Response[0]['CREDENTIAL_RUN']);

                    formx.user_run.value = Response[0]['CREDENTIAL_RUN'] + '-' + Response[0]['CREDENTIAL_DV'];
                    formx.pa_rolework.value = Response[0]['USER_ROLWORK'];
                    formx.pa_roleplatform.value = '';
                    formx.pa_OperationMine.value = Response[0]['USER_MININGGROUP'];
                    formx.pa_Business.value = Response[0]['USER_CORPORATE'];
                    formx.user_email.value = Response[0]['USER_EMAIL'];
                    formx.user_mobile.value = Response[0]['USER_MOBILE'];

                    let selectOptions = formx.pa_statusAccess.options;
                    selectOptions[0].value = Response[0]['STATUS_REQUEST'];
                    selectOptions[0].text = Response[0]['STATUS_REQUEST'];
                    formx.pa_statusAccess.selectedIndex = "0";
                }


                NotifyerPopUp.create('Data Loading...', titleAccounts, 'S');
            }

            let regload = new Date().getTime();
            //console.info('Time Load: ' + ((regload - regstart) / 60).toFixed(2) + ' s');
        })
        .catch(err => {
            console.error('ERR: ' + err.name);
            console.error('ERR: ' + err.message);
            console.error('ERR: ' + err.toString());
            console.error('ERR: ' + err.stack);
        });

}
