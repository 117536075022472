//
if (document.getElementById('radio_button_credential')) {
    // console.log('element docidentify');
    let radio = document.getElementsByName('docidentify');
    let lablurun = document.getElementById('lablurun');

    // run
    radio[0].addEventListener('change', function (evnt) {
        lablurun.innerHTML = 'RUN Cédula Identidad';
        
    })

    //  passport
    radio[1].addEventListener('change', function (evnt) {
        lablurun.innerHTML = 'Número Pasaporte';
        
    })
    //  dni
    // radio[2].addEventListener('change', function (evnt) {
    //     console.log('radio 2: ');
        
    // })
}
