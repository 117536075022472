//
function Switch_Environment(
    MininGroup: string
) {
    formx = document.forms['form_UserSettings'];
    formx.MininGroup.value = MininGroup;

    formx.action = '/System/Query/Settings/ChangeEnvironment';
    formx.target = '';
    formx.submit();

    console.log('MininGroup: ' + MininGroup);
}
