//** GET ACCESS TO PLATFORM */

function verify_DataForAccessRequest() {

    formx = document.forms['form_get_access'];
    
    try {
        // Questions
        Validation_Input(
            formx.nocredential,
            'Su Número de Credencial se encuentra en blanco o no fue ingresado',
            'Número Credencial',
            [formx.docidentify.value]
            );
    
        Validation_Input(
            formx.ubussiness,
            'La Empresa Asociada se encuentra en blanco o no fue agregada',
            'Empresa'
            );
    
    } catch (error) {
        InputController(error);
        return false;
    }
   
    AnimationWallProgress(true);
    formx.action = '/System/Query/Access/GetStatusAccount';
    formx.submit();
}

function clean_FormGetAccess() {
    formx = document.forms['form_get_access'];

    formx.nocredential.value = '';
    formx.ubussiness.value = '';
}
