//** REQUEST MFA RESET PASSWORD  */
function request_mfa_resetpass() {
    let endText = '. Favor verifique e intente nuevamente';
    let form_request_mfa = document.forms['form_request_mfa'];

    let mailvalid = form_request_mfa.mailvalid;

    if (mailvalid.value.trim() == '') {
        NotifyMssg = 'El Email se encuentra en blanco o no fue ingresado' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'I');
        mailvalid.focus();
        return false;
    }

    if (!val4.test(mailvalid.value.trim())) {
        NotifyMssg = 'Error email no valido' + endText;
        NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
        mailvalid.focus();
        return false;
    }

    if (mailvalid.value.trim() != '') {

        let regstart = new Date().getTime();
        const xpath = '/System/Service/Email/ControlCheckEmail';

        let frmData = new FormData;
        frmData.append('xemail', mailvalid.value.trim());

        const request = new Request(xpath, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'same-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: frmData,
            //headers: new Headers()
        });

        fetch(request)
            .then(res => {
                //console.info('status: ' + res.status);

                //return res.json();
                if (res.status == 200) {
                    return res.text();
                } else {
                    throw "Respuesta incorrecta del servidor"
                }
            })
            .then(data => {
                //console.log('data: ' + data);
                //console.log('length: ' + data.length);
                //console.log('isJson: ' + isJson(data));

                let jsonResponse = JSON.parse(data);

                if (jsonResponse['check'] == 'USED') {
                    NotifyMssg = 'Email usuario registrado.';
                    NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'S');

                    AnimationWallProgress(true);
                    form_request_mfa.action = '/System/Query/Accounts/RequestCodeMfa';
                    //?accn=account/sessions/actions/modgetgmfa
                    form_request_mfa.submit();

                } else {
                    NotifyMssg = 'Este Email NO se encuentra en nuestros sistemas, favor verifique.';
                    NotifyerPopUp.create(NotifyMssg, NotifyTitle, 'W');
                }

                let regload = new Date().getTime();
                console.info('Time Load: ' + ((regload - regstart) / 60).toFixed(2) + ' s');
            })
            .catch(err => {
                console.error('ERR: Fetch request_mfa_resetpass');
                console.error(err.name);
                console.error(err.message);
                console.error(err.toString());
                console.error(err.stack);
            });
    }
}
