// CHECK PASSWORD COMPARATION

function Passw_Confirm(password, confirmation) {

    if (password != confirmation) {
        document.getElementById('comm_confirm').innerHTML = '<span class="ltr_fred">Contraseñas Distinas</span>';
    } else {
        document.getElementById('comm_confirm').innerHTML = '<span class="ltr_fgre">Contraseñas Exactas</span>';
    }
    if (confirmation == '') {
        document.getElementById('comm_confirm').innerHTML = '';
    }
}

if (document.getElementById('enrpsswd')) {
    let enrpsswd = document.getElementById('enrpsswd');

    enrpsswd.addEventListener('keyup', function (e) {
        //console.info('keypress: ' + event.keyCode);
        // let form_unique = document.getElementById('form_unique');

        CheckPasswordStrength(enrpsswd.value);
    });
}

if (document.getElementById('qcpsswd')) {
    let confirmation = document.getElementById('qcpsswd');

    confirmation.addEventListener('keyup', function (e) {
        //console.info('keypress: ' + event.keyCode);
        //let form_Account = document.forms['form_account'];
        let password = document.getElementById('idpsswd');

        Passw_Confirm(password.value, confirmation.value);
    });
}
