//
if (document.getElementById('search_usx')) {
    let search_usx = document.getElementById('search_usx');

    search_usx.addEventListener('change', function (event) {

        formx = document.forms['form_updateAccount'];
        formx.request_list.selectedIndex = "0";
        let select_usx = formx.search_usx;

        transportInfoUser(select_usx, 'PT');
    })
}

if (document.getElementById('request_list')) {
    
    document.getElementById('request_list')
    .addEventListener('change', function (event) {

        formx = document.forms['form_updateAccount'];
        // formx.search_usx.selectedIndex = "0";
        // let request_list = formx.request_list;

        transportInfoUser(formx.request_list, 'RQ');
    })
}
